
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Dates from "@/components/inputs/Dates.vue";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";
import {IsEntity} from "@/entity/IsEntity";
import Times from "@/components/inputs/Times.vue";
import {BildirimEntity} from "@/entity/BildirimEntity";
import {HatirlaticiEntity} from "@/entity/HatirlaticiEntity";
import {Hatirlat, HatirlatLabel} from "@/enum/Hatirlat";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import {IsSablonEntity} from "@/entity/IsSablonEntity";
import {AxiosResponse} from "axios";

@Component({
  components: {Times, PersonelPicker, Dates, FormWrapper}
})

export default class IsForm extends Mixins(ObjectInputMixin) {
  @Prop() notId!: number;
  @Prop() takipId!: number;
  @Ref('formWrapper') formWrapper!: FormWrapper;

  localValue = new IsEntity();
  sablonIsler: Array<IsSablonEntity> = [];

  mounted() {
    this.loadSablon();
  }

  loadSablon() {
    this.$http.get('/api/v1/is-sablon').then((response) => {
      this.sablonIsler = response.data;
    })
  }

  sablonUygula(sablon: IsSablonEntity) {
    this.localValue.isim = sablon.isim;
    this.localValue.aciklama = sablon.aciklama;
    this.input();
  }

  async save() {
    this.formWrapper.onFly = true;
    let hatirlatici = new HatirlaticiEntity();
    if (this.localValue.tarih) {
      hatirlatici.baslik = 'İş';
      hatirlatici.aciklama = this.localValue.isim + ' işi başladı.';
      hatirlatici.planlanan_tarih = this.localValue.tarih;
      hatirlatici.planlanan_saat = this.localValue.saat ? this.localValue.saat : '00:00';
      hatirlatici.user_id = this.localValue.atanan_id;
    }
    if (this.localValue.id) {
      const defaultLocalValue: IsEntity = this.formWrapper.defaultLocalValue as IsEntity;
      if (defaultLocalValue.atanan_id &&
          this.localValue.atanan_id &&
          this.localValue.atanan_id != defaultLocalValue.atanan_id) {
        await this.$http.delete('/api/v1/personel/' + defaultLocalValue.atanan_id + '/hatirlatici/' + this.localValue.hatirlatici_id).catch((error) => {
          this.formWrapper.onFly = false;
          StatusCodeHelper.error(error);
        });
        let response: AxiosResponse<HatirlaticiEntity> = await this.$http.post('/api/v1/personel/' + this.localValue.atanan_id + '/hatirlatici', hatirlatici).catch((error) => {
          this.formWrapper.onFly = false;
          StatusCodeHelper.error(error);
        });
        this.localValue.hatirlatici_id = response.data.id;
      } else if (defaultLocalValue.atanan_id &&
          this.localValue.atanan_id &&
          this.localValue.atanan_id == defaultLocalValue.atanan_id) {
        await this.$http.put('/api/v1/personel/' + this.localValue.atanan_id + '/hatirlatici/' + this.localValue.hatirlatici_id, hatirlatici).catch((error) => {
          this.formWrapper.onFly = false;
          StatusCodeHelper.error(error);
        });
      } else if (defaultLocalValue.atanan_id && !this.localValue.atanan_id) {
        await this.$http.delete('/api/v1/personel/' + defaultLocalValue.atanan_id + '/hatirlatici/' + this.localValue.hatirlatici_id).catch((error) => {
          this.formWrapper.onFly = false;
          StatusCodeHelper.error(error);
        });
        delete this.localValue.hatirlatici_id;
      } else if (!defaultLocalValue.atanan_id && this.localValue.atanan_id) {
        let response: AxiosResponse<HatirlaticiEntity> = await this.$http.post('/api/v1/personel/' + this.localValue.atanan_id + '/hatirlatici', hatirlatici).catch((error) => {
          this.formWrapper.onFly = false;
          StatusCodeHelper.error(error);
        });
        this.localValue.hatirlatici_id = response.data.id;
      }
      if (this.localValue.atanan_id && this.localValue.atanan_id != defaultLocalValue.atanan_id) {
        let bildirim = new BildirimEntity();
        bildirim.baslik = 'İş Atandı';
        bildirim.aciklama = this.$store.state.user.first_name + ' ' + this.$store.state.user.last_name + ' size ' + this.localValue.isim + ' işini atadı.'
        bildirim.user_id = this.localValue.atanan_id
        await this.$http.post('/api/v1/personel/' + this.localValue.atanan_id + '/bildirim', bildirim).catch((error) => {
          this.formWrapper.onFly = false;
          StatusCodeHelper.error(error);
        });
      }
      await this.$http.put('/api/v1/is/' + this.localValue.id, this.localValue).then(() => {
        this.$toast.success('İş atandı.');
      }).catch((error) => {
        this.formWrapper.onFly = false;
        StatusCodeHelper.error(error);
      });
      this.formWrapper.onFly = false;
      this.$emit('onSuccess');
    } else {
      if (this.notId) this.localValue.not_id = this.notId;
      if (this.takipId) this.localValue.takip_id = this.takipId;
      if (this.localValue.atanan_id && this.localValue.tarih) {
        let hatirlaticiResponse: AxiosResponse<HatirlaticiEntity> = await this.$http.post('/api/v1/personel/' + this.localValue.atanan_id + '/hatirlatici', hatirlatici).catch((error) => {
          this.formWrapper.onFly = false;
          StatusCodeHelper.error(error);
        });
        this.localValue.hatirlatici_id = hatirlaticiResponse.data.id;
      }
      await this.$http.post('/api/v1/is', this.localValue).then(() => {
        this.$toast.success('İş atandı.');
      });
      if (this.localValue.atanan_id) {
        let bildirim = new BildirimEntity();
        bildirim.baslik = 'İş Atandı';
        bildirim.aciklama = this.$store.state.user.first_name + ' ' + this.$store.state.user.last_name + ' size ' + this.localValue.isim + ' işini atadı.'
        if (this.takipId)
          bildirim.aciklama += '(Kart numarası:' + this.takipId + ')';
        bildirim.user_id = this.localValue.atanan_id
        await this.$http.post('/api/v1/personel/' + this.localValue.atanan_id + '/bildirim', bildirim).catch((error) => {
          this.formWrapper.onFly = false;
          StatusCodeHelper.error(error);
        });
      }
      this.formWrapper.onFly = false;
      this.$emit('onSuccess');
    }
  }
}
