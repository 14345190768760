
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import IstihkakList from "@/components/lists/haciz/IstihkakList.vue";
import TemlikList from "@/components/lists/haciz/TemlikList.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import SeksenDokuzForm from "@/components/forms/haciz/SeksenDokuzForm.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import IhbarnameAdresBilgileriList from "@/components/lists/haciz/IhbarnameAdresBilgileriList.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "@/components/inputs/Dates.vue";
import {SeksenDokuzTebligatEntity} from "@/entity/Haciz/SeksenDokuzTebligatEntity";
import TarafListPicker from "@/components/pickers/uyap/TarafListPicker.vue";
import AvukatTalepAdresTuruPicker from "@/components/pickers/uyap/AvukatTalepAdresTuruPicker.vue";
import AvukatTalepTebligatTuruPicker from "@/components/pickers/uyap/AvukatTalepTebligatTuruPicker.vue";
import UcuncuSahisPicker from "@/components/pickers/uyap/UcuncuSahisPicker.vue";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";

@Component({
  components: {
    UcuncuSahisPicker,
    AvukatTalepTebligatTuruPicker,
    AvukatTalepAdresTuruPicker,
    TarafListPicker,
    Dates,
    Tutar,
    IhbarnameAdresBilgileriList,
    FormDialog, SeksenDokuzForm, EnabledSwitch, TemlikList, IstihkakList
  }
})
export default class Ihbarname891HacizForm extends Mixins(ObjectInputMixin) {
  @Prop() kisiKurumId!: number;
  @Prop() dosyaId!: number;
  @Prop() avukatTalepList!: Array<AvukatTalepListCevapRow>;

  seksenDokuzIki: boolean = false;
  seksenDokuzUc: boolean = false;

  @Watch("localValue.ekstra_data.seksen_dokuz_bir_tebligat")
  onItirazChange() {
    if (this.localValue.ekstra_data.seksen_dokuz_bir_tebligat?.itiraz_var_mi === true) {
      this.seksenDokuzIki = true;
      if (this.localValue.ekstra_data.seksen_dokuz_iki_tebligat?.itiraz_var_mi === true) {
        this.seksenDokuzUc = true;
      } else {
        this.localValue.ekstra_data.seksen_dokuz_uc_tebligat = new SeksenDokuzTebligatEntity();
        this.seksenDokuzUc = false;
      }
    } else if (this.localValue.ekstra_data.seksen_dokuz_bir_tebligat?.itiraz_var_mi === false) {
      this.localValue.ekstra_data.seksen_dokuz_iki_tebligat = new SeksenDokuzTebligatEntity();
      this.localValue.ekstra_data.seksen_dokuz_uc_tebligat = new SeksenDokuzTebligatEntity();
      this.seksenDokuzIki = false;
      this.seksenDokuzUc = false;
    }
  }

  mounted() {
    if ((!this.localValue.uyap_data || this.localValue.uyap_data.talepKodu != this.localValue.haciz_turu.talep_kodu) && this.$store.getters.isUyapConnected) {
      let talep = this.avukatTalepList.find(value => value.talepKodu == this.localValue.haciz_turu.talep_kodu);
      this.localValue.uyap_data = {...talep};
      this.localValue.uyap_data.sorguId = '';
      this.localValue.uyap_data.dosyaId = this.dosyaId;
      this.localValue.uyap_data.kisiKurumId = this.kisiKurumId;
      this.input();
    }
  }

  tebligatAdresSorgula() {
    // IcraTebligatTalebiAdresSorgula
  }
}
